<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">历思利用毛发检测，高新技术打破海南禁毒“滞案”僵局！</div>
      <p class="time">2018.09.13</p>
      <p class="space_content">
        海南拥有美丽的生态环境，是全国最大的经济特区和唯一的省域国际旅游岛。然而，受国际毒潮泛滥和国内多种因素的影响，毒品问题一度成为影响当地社会安定和民生安康的源头性、根本性问题。为了贯彻落实习近平总书记关于禁毒工作的重要指示精神，海南省全面开展的“禁毒2018两打两控”和“夏季攻势”的行动。近日，海南省陵水县公安局将50名有吸毒历史的人，再次传唤到公安局进行复检，检测是否有复吸再成瘾的情况。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/16-1.png')">
      </div>


      <p class="space_content">
        从海南省陵水县公安局了解到，毒品检测常规鉴定方法有两种，一种是现场快速筛选，是通过在现场用尿检板直接测试是否存在吸毒的情况，其检测结果的准确度不高，也会发生假阳性的情况。另一种方法是实验室检测方法，是通过采集人的血样、尿液进行毒品检测，不同种类的检材具有不同的吸毒追溯期。例如，毒品在尿液中的追溯期一般不超过3天，一旦尿检呈阳性，通常表明被检测人员在3天内使用过毒品；血液检测毒品呈阳性，能表明被检测人员在几小时（一般不超过24小时）内使用过毒品。一旦超过期限，血液、尿液中便检测不到毒品成分。
      </p>
      <p class="space_content">
        而对于有吸毒历史的人或者戒毒者来说，在进行复检时，再用现场快速筛选和实验室检测的方法，其检测的结果是难以确定他们是否复吸、是否再成瘾的。另外，还有一些特殊情况，因嫌疑人自身的某些特殊原因不方便进行血样和尿液的提取，导致掌握不到涉毒证据，案件陷入僵局。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/16-2.png')">
      </div>

      <p class="space_content">
        所以，复检这50名有吸毒历史的人，要确定他们是否复吸再成瘾的情况，海南陵水县公安局再使用常规的鉴定方法显然是不合适了，且收效也相当有限。为了提高办案成效，海南省陵水县公安局积极探索新的鉴定与办案思路。
      </p>

      <p class="space_content">
        据悉，历思除了在毛发检测上出类拔萃外，其法医毒物鉴定技术先进性还体现在：
      </p>
      <p class="space_content">
        1、毒品检测精度能够达到纳克级别，显著高于当前业界水平，可最大限度减少漏网之鱼；
      </p>
      <p class="space_content">
        2、可对一份检材进行十多个项目的毒品鉴定，涵括了6-单乙酰吗啡、吗啡、甲基苯丙胺、苯丙胺、四氢大麻酚、四氢大麻酸、氯胺酮、可卡因、3,4-亚甲二氧基甲基苯丙胺、3,4-亚甲二氧基苯丙胺、苯甲酰爱康宁等目前主要毒品成分。还可根据需要加测其他项目，从而实现检材的充分利用，大大提高办案效率；
      </p>
      <p class="space_content">
        3、检材并不局限于毛发、血样、尿液，唾液、人体组织等均可用以鉴定；检材形态可以是固体、液体、固液混合等，大大拓展了证据获取的路径。
      </p>
      <p class="space_content">
        海南省陵水县公安局确认过信息之后，迅速与福建历思司法鉴定所建立了业务合作。立即提取这50名有吸毒历史的人的毛发作为检材，送往历思进行毛发毒品检测。了解到案情紧急后，历思组织工作人员加班加点，在很短的时间里完成了鉴定工作。鉴定结果显示，在这50名有吸毒历史的人中，发现有13名被检出吸食过海洛因、冰毒、大麻等多种毒品，并出具了司法鉴定意见书作为案件办理的证据。海南省陵水县公安局根据福建历思司法鉴定所出具的司法鉴定意见书，很快对这13名复吸人员进行处理，禁毒工作也取得了阶段性的突破。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/16-3.png')">
      </div>

      <p class="space_content">
        在禁毒的工作中，常规鉴定技术的现场快速筛查和实验室检测方法有一定的局限性，严重了影响了办案成效。而利用毛发进行高科技的毒品检测技术，能够有效解决尿检、血样检测措施中，追溯期短所带来的种种弊端，也能够极大的提高公安机关的工作效率,节省了人力物力,使禁毒各项的工作取得了良好的成效。
      </p>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/15'"><p class="ellipsis1">历思案件被评选上了中国法律服务网，被司法行政（法律服务）收录为典型案例啦！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/17'"><p class="ellipsis1">协会状告养猪人索赔150万元，历思助力厦门首例环境公益诉讼案！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
